import {TFunction} from 'i18next';

import {AgentSavedFilterFormTypes} from 'appRedux/actions/agentFilters/types';
import {BoardResponseType, RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {BoardStatusTypes} from 'appRedux/actions/workflow/types';

import {AGENT_TOP_BAR_HEIGHT} from 'components/AgentScreenComponents/helper';

import {VIEW_MODE_TABLE} from 'helpers/filter';

import {
    ITEMS_PER_PAGE_FOR_CASES,
    ITEMS_PER_PAGE_BOARD,
    PARAMETER_AGENT,
    PARAMETER_FIRST_LETTER,
    PARAMETER_FORM,
    PARAMETER_LAST_CREATED,
    PARAMETER_TAG,
    PARAMETER_LAST_UPDATED,
} from 'config/index';
import {theme} from 'config/theme';

export const FILTERS_PANEL_HEIGHT = 113;
export const SELECTED_FILTERS_PANEL_HEIGHT = 30;
export const REQUESTER_CASE_BLOCK_WIDTH = 370;
export const COLUMN_HEADER_HEIGHT = 56;
export const DASHBOARD_SCROLLBAR_WIDTH = 13;

export const filterParameters = [
    PARAMETER_FORM,
    PARAMETER_FIRST_LETTER,
    PARAMETER_AGENT,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    PARAMETER_TAG,
];

export const getHeaderTop = (showFiltersPanel: boolean, filtersNumber: number): number => {
    let result = AGENT_TOP_BAR_HEIGHT + COLUMN_HEADER_HEIGHT;
    if (showFiltersPanel) {
        result += FILTERS_PANEL_HEIGHT;
    }
    if (filtersNumber > 0 && showFiltersPanel) {
        result += SELECTED_FILTERS_PANEL_HEIGHT;
    }
    return result - 1;
};

export const getPerPageForRequest = (
    perPageFromUrl: string | null,
    viewModeFromUrl: string | null,
    itemsPerPage: number,
): string => {
    if (!perPageFromUrl) {
        if (viewModeFromUrl === VIEW_MODE_TABLE) {
            return String(ITEMS_PER_PAGE_FOR_CASES);
        }
        if (itemsPerPage && itemsPerPage > 0) {
            return String(itemsPerPage);
        }
        return String(ITEMS_PER_PAGE_BOARD);
    }
    return perPageFromUrl;
};

export const getSavedFilterModalTitle = (t: TFunction, updatedFilter: AgentSavedFilterFormTypes | null): string => {
    if (updatedFilter) {
        return t('orguser.savedFilters.updateFilter').replace('%TITLE%', `"${updatedFilter.title}"`);
    }
    return t('orguser.savedFilters.typeNameOfYourFilter');
};

export interface BoardViewCasesTypes {
    status: string;
    statusType: string;
    permittedUuids: string[];
    casesNumber: number;
    cases: RequesterCaseResponseTypes[];
}

export const getBoardWidth = (columnNumber = 6, blockWidth: number): number => {
    return columnNumber * (blockWidth || REQUESTER_CASE_BLOCK_WIDTH) + 50;
};

export const getBoardViewColumns = (items: BoardResponseType): BoardViewCasesTypes[] => {
    const results: BoardViewCasesTypes[] = [];
    for (const status in items) {
        results.push({
            status,
            statusType: items[status]?.statusType || '',
            permittedUuids: items[status]?.permittedUuids || [],
            casesNumber: items[status]?.count || 0,
            cases: items[status]?.results || [],
        });
    }
    return results;
};

export const getCustomBoardsColumnNumber = (statuses: BoardStatusTypes[]): number => {
    const visibleStatuses = statuses.filter(item => item.isActive);
    return visibleStatuses.length;
};

export const getDragColumnColor = (isDragPossible: boolean): string => {
    return isDragPossible ? '#d3daef' : theme.palette.secondary.contrastText;
};
