import React, {DragEvent, Dispatch, SetStateAction, FC, ReactNode, useRef} from 'react';
import {throttle} from 'lodash';

import Box from '@mui/material/Box';

import DraggableSvgIcon from 'assets/icons/buttons/DraggableSvgIcon';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface DraggableBlockRowType {
    item: any;
    children: ReactNode;
    dragItem?: string;
    setDragItem: Dispatch<SetStateAction<string>>;
    dropTo?: number;
    setDropTo: Dispatch<SetStateAction<number>>;
    onDragEnd: () => void;
}

const DraggableBlockRow: FC<DraggableBlockRowType> = ({
    item,
    dragItem,
    setDragItem,
    dropTo,
    setDropTo,
    onDragEnd,
    children,
}) => {
    const {uuid, priority} = item;

    const dragEndTimeoutRef = useRef<NodeJS.Timeout>();

    const onDragStart = () => {
        setDragItem(uuid);
        setTimeout(() => {
            setDropTo(priority);
        }, 0);
    };

    const onDrag = () => {
        dragEndTimeoutRef.current && clearTimeout(dragEndTimeoutRef.current);
        dragEndTimeoutRef.current = setTimeout(() => {
            setDragItem && setDragItem('');
        }, 1000);
    };

    const onDragEnter = () => {
        setTimeout(() => {
            setDropTo(priority);
        }, 0);
    };

    const onDragOver = throttle((event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    }, 500);

    return (
        <Box
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDrag={onDrag}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            draggable
            sx={{
                position: 'relative',
                opacity: dragItem && dropTo === priority ? 0.5 : 1,
                background: dragItem && dropTo === priority ? CLIENT_BACKGROUND_COLOR : 'white',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <DraggableSvgIcon isDisabled={dragItem !== uuid} />
                <Box>{children}</Box>
            </Box>
        </Box>
    );
};

export default DraggableBlockRow;
