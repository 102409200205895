import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';
import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';
import {GET_REQUESTER_CASE_COMMENTS} from 'appRedux/actions/comments';
import {FormTranslationItemTypes} from 'appRedux/actions/forms/types';

import {CryptoContext} from 'contexts/crypto/context';
import {CaseKeyContext} from 'contexts/caseKey/context';
import {MediaContext} from 'contexts/media/context';
import {RouteContext} from 'contexts/route/context';

import FormPage from 'pages/client/form/partials/FormPage';
import {getDecodedResults, checkIsLastSubPageForm} from 'pages/client/form/helper';
import PageSwitcher from 'pages/client/form/partials/PageSwitcher';
import PageSwitcherWrapper from 'pages/client/form/partials/PageSwitcherWrapper';

import {getFormKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {DEFAULT_PAGE} from 'config/index';

const ClientForm: FC = () => {
    const {requestCase, form} = useParams();
    const dispatch = useDispatch();

    const {
        admin: {formInfo},
        profile: {profile},
        requestCase: {
            currentCase: {formId, formTitle, result, isEncryptInfo, status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {formPage, setFormPage, setFormSection} = useContext(RouteContext);
    const {keys} = useContext(CryptoContext);
    const {encodedCaseKey} = useContext(CaseKeyContext);
    const {isMobile} = useContext(MediaContext);

    const [currentPage, setCurrentPage] = useState<number>(formPage ?? DEFAULT_PAGE);
    const [clientInformation, setClientInformation] = useState({});

    const handleSetPage = (page: number) => {
        setFormPage(page);
        setCurrentPage(page);
    };

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseComments = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_COMMENTS.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangePopupInformation = (pageId: number, sectionId: number, popupId: number, value: unknown) => {
        const pageKeyword = `page-${pageId}`;
        const sectionKeyword = `section-${sectionId}`;
        const popupKeyword = `popup-${popupId}`;

        const pageContent = get(clientInformation, [pageKeyword], {});
        const sectionContent = get(clientInformation, [pageKeyword, sectionKeyword], {});

        setClientInformation({
            ...clientInformation,
            [pageKeyword]: {
                ...pageContent,
                [sectionKeyword]: {
                    ...sectionContent,
                    [popupKeyword]: value,
                },
            },
        });
    };

    const handleChangeInformation = (pageId: number, sectionId: number, fieldId: number, value: string) => {
        const pageKeyword = `page-${pageId}`;
        const sectionKeyword = `section-${sectionId}`;
        const fieldKeyword = `field-${fieldId}`;

        const pageContent = get(clientInformation, [pageKeyword], {});
        const sectionContent = get(clientInformation, [pageKeyword, sectionKeyword], {});

        setClientInformation({
            ...clientInformation,
            [pageKeyword]: {
                ...pageContent,
                [sectionKeyword]: {
                    ...sectionContent,
                    [fieldKeyword]: value,
                },
            },
        });
    };

    const handleCompletePage = (pageId: number) => {
        const pageKeyword = `page-${pageId}`;

        const pageContent = get(clientInformation, [pageKeyword], {});

        setClientInformation({
            ...clientInformation,
            [pageKeyword]: {
                ...pageContent,
            },
        });
    };

    useEffect(() => {
        getRequesterCaseComments({id: requestCase});
    }, []);

    useEffect(() => {
        if (profile) {
            getFormByCaseInformation({id: requestCase});
        }
    }, [profile]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (isEncryptInfo) {
                if (result && keys && encodedCaseKey) {
                    const currentResults = await getDecodedResults(result, encodedCaseKey, keys);
                    setClientInformation({
                        enc: isEncryptInfo,
                        ...currentResults,
                    });
                }
            } else {
                setClientInformation(result);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [result, encodedCaseKey, isEncryptInfo]);

    useEffect(() => {
        return () => {
            setFormSection(null);
        };
    }, []);

    const formTitleKeyword = formId ? getFormKeyword(formId, 'title') : '';
    const pages: FormPageTypes[] = get(formInfo, 'pages', []);
    const translations: FormTranslationItemTypes[] = get(formInfo, 'translations', []);

    return (
        <Box>
            <PageSwitcherWrapper>
                <Typography
                    align="center"
                    sx={{
                        fontSize: isMobile ? 24 : 36,
                        fontWeight: 600,
                        mt: isMobile ? 1 : 3,
                        mb: 3,
                    }}
                >
                    {getFormTranslatedLabel(translations, formTitleKeyword, formTitle)}
                </Typography>
                <PageSwitcher
                    clientInformation={clientInformation}
                    currentPage={currentPage}
                    setCurrentPage={handleSetPage}
                    pages={pages}
                    status={status}
                />
            </PageSwitcherWrapper>
            <Box>
                {pages &&
                    pages.map((page: FormPageTypes, index: number) => {
                        return (
                            <FormPage
                                key={`formPage-${index}`}
                                formId={Number(form)}
                                page={page}
                                isLastPage={pages.length === index + 1}
                                isActive={currentPage === index + 1}
                                clientInformation={clientInformation}
                                handleChangeInformation={handleChangeInformation}
                                handleChangePopupInformation={handleChangePopupInformation}
                                handleCompletePage={handleCompletePage}
                                currentPage={currentPage}
                                setCurrentPage={handleSetPage}
                                isLastSubFormPage={checkIsLastSubPageForm(pages, index)}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
};

export default ClientForm;
