import {
    GET_CHAT_MESSAGES,
    CHAT_DOCUMENT_URL,
    UPLOAD_CHAT_DOCUMENT,
    UPDATE_CHAT_MESSAGE,
    DELETE_CHAT_MESSAGE,
    CHAT_AGENT_CONTACTS,
    GET_PREV_CHAT_MESSAGES,
    GET_NEW_CHAT_MESSAGES,
} from 'appRedux/actions/requestChat';
import {
    ChatMessageTypes,
    ChatContactTypes,
    MessageTypes,
    ChatMessagesResponseTypes,
} from 'appRedux/actions/requestChat/types';

export const initialState: RequestChatState = {
    isLoading: false,
    isFileLoading: false,
    messages: [],
    newestMessageId: '',
    oldestMessageId: '',
    allMessagesLoaded: false,
    contacts: [],
    errors: undefined,
};

export interface RequestChatState {
    isLoading: boolean;
    isFileLoading: boolean;
    messages: ChatMessageTypes;
    newestMessageId: string;
    oldestMessageId: string;
    allMessagesLoaded: boolean;
    contacts: ChatContactTypes[];
    errors: string[] | undefined;
}

const chat = (
    state = initialState,
    action: IActionType<ChatMessageTypes | ChatContactTypes[] | MessageTypes | ChatMessagesResponseTypes>,
): RequestChatState => {
    switch (action.type) {
        case GET_CHAT_MESSAGES.REQUEST:
        case GET_PREV_CHAT_MESSAGES.REQUEST:
        case GET_NEW_CHAT_MESSAGES.REQUEST:
        case UPDATE_CHAT_MESSAGE.REQUEST:
        case DELETE_CHAT_MESSAGE.REQUEST:
        case CHAT_DOCUMENT_URL.REQUEST:
        case CHAT_AGENT_CONTACTS.REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case UPLOAD_CHAT_DOCUMENT.REQUEST: {
            return {
                ...state,
                isFileLoading: true,
            };
        }

        case GET_CHAT_MESSAGES.SUCCESS: {
            const payload = action.payload as ChatMessagesResponseTypes;
            const allMessages = payload.messages;

            return {
                ...state,
                isLoading: false,
                messages: allMessages,
                newestMessageId: allMessages?.[allMessages.length - 1]?.uuid || '',
                oldestMessageId: allMessages?.[0]?.uuid || '',
                allMessagesLoaded: payload.isAllLoaded,
            };
        }

        case DELETE_CHAT_MESSAGE.SUCCESS: {
            const removedUuid = String(action.payload);
            const currentMessages = state.messages;
            const newMessages: MessageTypes[] = [];
            currentMessages.forEach((item: MessageTypes) => {
                if (item.uuid !== removedUuid) {
                    newMessages.push(item);
                }
            });
            return {
                ...state,
                isLoading: false,
                messages: newMessages,
            };
        }

        case GET_PREV_CHAT_MESSAGES.SUCCESS: {
            const payload = action.payload as ChatMessagesResponseTypes;
            const prevMessages = payload.messages.filter(msg => !state.messages.find(stMsg => stMsg.uuid === msg.uuid));

            return {
                ...state,
                isLoading: false,
                messages: [...prevMessages, ...state.messages],
                oldestMessageId: prevMessages?.[0]?.uuid || state.oldestMessageId,
                allMessagesLoaded: payload.isAllLoaded,
            };
        }

        case GET_NEW_CHAT_MESSAGES.SUCCESS: {
            const newMessages = (action.payload as ChatMessageTypes).filter(
                msg => !state.messages.find(stMsg => stMsg.uuid === msg.uuid),
            );
            return {
                ...state,
                isLoading: false,
                messages: [...state.messages, ...newMessages],
                newestMessageId: newMessages?.[newMessages.length - 1]?.uuid || state.newestMessageId,
            };
        }

        case CHAT_DOCUMENT_URL.SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case UPLOAD_CHAT_DOCUMENT.SUCCESS: {
            const newMessages = (action.payload as ChatMessageTypes).filter(
                msg => !state.messages.find(stMsg => stMsg.uuid === msg.uuid),
            );

            return {
                ...state,
                isFileLoading: false,
                messages: [...state.messages, ...newMessages],
                newestMessageId: newMessages?.[newMessages.length - 1]?.uuid || '',
            };
        }

        case CHAT_AGENT_CONTACTS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                contacts: action.payload as ChatContactTypes[],
            };
        }

        case UPDATE_CHAT_MESSAGE.SUCCESS: {
            const updatedMessage = action.payload as MessageTypes;
            const currentMessages = state.messages;
            const newMessages: MessageTypes[] = [];
            currentMessages.forEach((item: MessageTypes) => {
                if (item.uuid !== updatedMessage.uuid) {
                    newMessages.push(item);
                } else {
                    newMessages.push(updatedMessage);
                }
            });
            return {
                ...state,
                isLoading: false,
                messages: newMessages,
            };
        }

        case GET_CHAT_MESSAGES.ERROR:
        case UPLOAD_CHAT_DOCUMENT.ERROR:
        case UPDATE_CHAT_MESSAGE.ERROR:
        case DELETE_CHAT_MESSAGE.ERROR:
        case CHAT_AGENT_CONTACTS.ERROR:
        case CHAT_DOCUMENT_URL.ERROR: {
            return {
                ...state,
                isLoading: false,
                isFileLoading: false,
                errors: action.payload as any,
            };
        }

        default:
            return state;
    }
};

export default chat;
